// libs
import { Application } from 'stimulus';

// shared components
import cancelSubscription from 'Shared/Component/Dialog/Presentation/CancelSubscription/CancelSubscription';
import form from 'Shared/Component/Form/Form';
import listingDetailDescription from 'Shared/Component/ListingDetailDescription/ListingDetailDescription';
import listingContactInfo from 'Shared/Component/ListingContactInfo/Presentation/ListingContactInfo';
import mapListingSummaryControl from 'Shared/Component/MapListingSummaryControl/MapListingSummaryControl';
import searchControls from 'Shared/Component/SearchControls/Presentation/SearchControls';
import textControl from 'Shared/Component/TextControl/TextControl';

// label components (should use shared)
import agentSummary from 'Pararius/Component/AgentSummary/AgentSummary';
import contactAgentHuurprofiel from 'Pararius/Component/Form/ContactAgentHuurprofiel/Presentation/ContactAgentHuurprofiel';
import homeownerLeadForm from 'Pararius/Component/Form/HomeownerLead/HomeownerLead';
import huurProfiel from "Pararius/Component/Form/HuurProfiel/Presentation/HuurProfiel";
import loadingIndicator from 'Pararius/Component/LoadingIndicator/LoadingIndicator';
import mailAlertFromSearchDialog from 'Pararius/Component/Dialog/MailAlertFromSearch/MailAlertFromSearch';
import mediaMosaic from 'Pararius/Component/MediaMosaic/MediaMosaic';
import projectPremiumSignupModal from 'Pararius/Component/Modal/ProjectPremiumSignup/ProjectPremiumSignup';
import quickAccessFilters from 'Pararius/Component/QuickAccessFilters/QuickAccessFilters';
import searchFilter from 'Pararius/Component/SearchFilter/SearchFilter';
import searchFilters from 'Pararius/Component/SearchFilters/SearchFilters';
import searchFiltersGroup from 'Pararius/Component/SearchFiltersGroup/SearchFiltersGroup';
import searchFiltersModal from 'Pararius/Component/Modal/SearchFilters/SearchFilters';
import searchListSorting from 'Pararius/Component/SearchListSorting/SearchListSorting';

// pages
import agentDetailPage from 'Page/Agent/Detail/Detail';
import listingDetailPage from 'Page/ListingDetail/ListingDetail';
import listingReportProblemPage from 'Page/ListingReportProblem/ListingReportProblem';
import homeownerLeadPage from 'Pararius/Page/HomeownerLead/Presentation/HomeownerLead';
import listingContactAgentPage from 'Pararius/Page/Listing/ContactAgent/ContactAgent';
import listingDetail from 'Pararius/Page/Listing/Detail/Detail';
import listingDetailProjectPremiumPage from 'Pararius/Page/ListingDetailProjectPremium/ListingDetailProjectPremium';
import listingSearchPage from 'Pararius/Page/Listing/Search/Search';
import manageSubscriptionPage from 'Pararius/Page/Account/Subscription/Presentation/Manage';
import page from 'Pararius/Page/Page';

const controllers = [
    agentDetailPage,
    agentSummary,
    cancelSubscription,
    contactAgentHuurprofiel,
    form,
    homeownerLeadForm,
    homeownerLeadPage,
    huurProfiel,
    listingContactAgentPage,
    listingContactInfo,
    listingDetail,
    listingDetailDescription,
    listingDetailPage,
    listingDetailProjectPremiumPage,
    listingReportProblemPage,
    listingSearchPage,
    loadingIndicator,
    mailAlertFromSearchDialog,
    manageSubscriptionPage,
    mapListingSummaryControl,
    mediaMosaic,
    page,
    projectPremiumSignupModal,
    quickAccessFilters,
    searchControls,
    searchFilter,
    searchFilters,
    searchFiltersGroup,
    searchFiltersModal,
    searchListSorting,
    textControl,
];

window.stimulusApplication = Application.start();

controllers.forEach(controllerModule => {
    window.stimulusApplication.register(controllerModule.name, controllerModule.controller);
});
