import mailAlertFromSearchDialog from 'Shared/Component/Dialog/MailAlertFromSearch/MailAlertFromSearch';
import { findOne, findAll } from 'Shared/Helper/Dom/Dom';

class MailAlertFromSearchDialog extends mailAlertFromSearchDialog.controller {
    initialize () {
        this.bindMethodsToSelf([
            'onFrequencyOptionChanged',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        const frequencyOptions = findAll('.radio-control [name="frequency"]', this.element);
        frequencyOptions.forEach(option => {
            option.addEventListener('change', this.onFrequencyOptionChanged);
        });
    }

    onFrequencyOptionChanged () {
        const emailField = findOne('.dialog__email', this.element);

        if (!emailField) {
            return;
        }

        const form = findOne('form', this.element);

        let emailValue, messageName;

        if (this.form.elements.frequency.value === 'direct') {
            this.lastEmailValue = this.form.elements.email.value;

            emailValue = '';
            messageName = 'disableFormFields';

            this.messageBus.postMessage({
                'message': 'enableSubmitButton',
            });
        } else {
            emailValue = this.lastEmailValue || '';
            messageName = 'enableFormFields';
        }

        this.messageBus.postMessage({
            'message': messageName,
            'data': {
                'scope': ['mailalert-from-search'],
                'values': {
                    'mailalert-from-search': emailValue,
                },
            },
        });

        emailField.hidden = form.elements.frequency.value === 'direct';
    }
}

export default {
    'name': mailAlertFromSearchDialog.name,
    'controller': MailAlertFromSearchDialog,
};
